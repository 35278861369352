import React from "react"

import { graphql } from "gatsby"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"

import Layout from "/src/components/layout"
import ContentLayout from "/src/components/content-layout"
import Seo from "/src/components/seo"
import AnchorLink  from "/src/components/link-anchor"
import Assets  from "/src/components/link-assets"
import AssetsDirect  from "/src/components/link-assets-direct"
import External from "/src/components/link-external"
import DataList from "/src/components/datalist"
import DataListRow from "/src/components/datalist-row"

import toppic01 from "/src/images/top-pic-01.png"
import topicon01 from "/src/images/top-icon-01.png"
import topicon02 from "/src/images/top-icon-02.png"
import topicon03 from "/src/images/top-icon-03.png"
import topicon04 from "/src/images/top-icon-04.png"

const Page = ({data}) => {
  const bredcrumb = [
    {
      pathname: '/',
      crumbLabel: 'Home'
    },
  ]
  const {t} = useTranslation();
  const lang = data.locales.edges[0] ? data.locales.edges[0].node.language : 'ja' 

  return(
    <Layout bredcrumb={bredcrumb} lang={lang}>
      <Seo title={t("筑波キャンパスについて")} lang={lang} />
      <ContentLayout lang={lang}>
        <h1>{t("筑波キャンパスについて")}</h1>
        
        <img src={ toppic01 } alt=""  className="img-wide"/>
        
        <h2 id="anchor01">{t("理化学研究所筑波キャンパスの概要")}</h2>
        
        <p>
          {t("理化学研究所（理研）は、わが国の産業の発展に資する科学研究を行うことを目的に1917（大正6）年に設立されました。以来、日本唯一の自然科学の総合研究所として、物理、化学、工学、生物学、医科学など幅広い分野において基礎から応用までの研究を進めています。埼玉県和光市に本部を置くほか、国内に9つの拠点、海外に5つの拠点を有しています。")}
        </p>
        <p>
          {t("筑波キャンパスは、1984（昭和59）年に、理研の国内9拠点のうちで1番最初の拠点として、国の研究機関や企業の研究施設が集積する「筑波研究学園都市」に開設されました。開設当初は黎明期にあった遺伝子組換え技術の安全性評価研究等を推進し、2001（平成13）年からはライフサイエンス研究の推進に必要不可欠な研究材料＝バイオリソースを収集し、保存し、提供する事業を推進しています。")}
        </p>
        <p>
          {t("年間約1万5千件のバイオリソースを日本国内の大学、研究機関、企業はもちろん世界各国の研究者の方々に提供しています。健康・医療・食料・環境問題など人類社会が直面するさまざまな問題解決に資する研究開発に利用頂いています。")}
        </p>

        <ul className="child-pages-icon">
          <li>
            <Link to="/campus/history/">
              <img src={ topicon01 } alt=""  width="64"/>
              <span className="title">{t("沿革")}</span>
            </Link>
          </li>
          <li>
            <Link to="/campus/organization/">
              <img src={ topicon02 } alt=""  width="64"/>
              <span className="title">{t("組織図")}</span>
            </Link>
          </li>
          <li>
            <Link to="/campus/facilities/">
              <img src={ topicon03 } alt=""  width="53"/>
              <span className="title">{t("所内施設")}</span>
            </Link>
          </li>
          <li>
            <Link to="/campus/disclosure/">
              <img src={ topicon04 } alt=""  width="49"/>
              <span className="title">{t("公開情報")}</span>
            </Link>
          </li>
        </ul>
        
        
      </ContentLayout>
    </Layout>
  )
}
export default Page

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    assets:allFile(filter: {sourceInstanceName: {eq: "assets"}}) {
      edges {
        node {
          base
          name
          extension
          prettySize
        }
      }
    }
  }
`;